body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1{
  margin-left: 20px;
}

.home-info{
  margin-left: 20px;
  width: 40%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.nav-links {
  padding: 12px 0px;
  background-color: gray;
  color: black;
  list-style: none;
  display: flex;
  justify-content: flex-start;
  margin: 0;
}


.nav-link {
  color: white;
  text-decoration: none;
  border-radius: 18px;
  padding: 6px 24px;
  font-size: 18px;
}

.home {
  width: 90%;
  height: 500px;
  margin: 30px auto;
  display: grid;
  grid-template: 1fr 1fr 1fr 1fr 1fr/ 1fr 1fr 1fr;
  border-style: groove;
  background-color: #f2f2f2;
  border-radius: 5px;
  
}

.table-cont{
  overflow-y: auto;
  overflow-x: visible;
  grid-row-start: 1;
  grid-row-end: 6;
  grid-column-start: 1;
  grid-column-end: 3;
  background-color: #ececec;
  border-style: groove;
  margin: 15px 15px;
  border-radius: 5px;
}

.table{
  margin: 20px 20px;
}

th, td {
  padding:8px ;
  cursor:pointer;
}

.clickeado{
  background-color: #acacac;
}

.container-right{
  grid-row-start: 1;
  grid-row-end: 6;
  margin: 15px 15px;
  display: grid;
  grid-template: 1fr 1fr 1fr 1fr 1fr/ 1fr;
}

.leafletContainer{
  height: 100%;
  width: 100%;
  border-style: groove;
  grid-row-start: 1;
  grid-row-end: 5;
  border-radius: 15px;
}

.container-right button{
  height: 50px;
  width: 140px;
  grid-row-start: 5;
  grid-row-end: 7;
  margin: auto;
  padding: 10px 10px 10px 10px;
  border-radius: 5px;
  font-size: 1.2em;
  cursor:pointer;
}

.expanded{
  background-color:white;
  border-style: groove;
}

.home-2 {
  width: 90%;
  height: 500px;
  margin: 30px auto;
  display: grid;
  grid-template: 1fr 1fr 1fr 1fr 1fr/ 1fr 1fr;
  border-style: groove;
  background-color: #f2f2f2; 
  border-radius: 5px;
}

.cards{
  grid-row-start: 1;
  grid-row-end: 2;
  grid-column-start: 1;
  grid-column-end: 2;
  display: flex;
  justify-content: space-around;
  margin: 10px 15px;
  background-color: #ececec;
  
}
.card{
  border-style: groove;
  text-align: center;
  width: 100%;
  border-radius: 5px;
}
.table-cont-2{
  overflow-y: auto;
  overflow-x: visible;
  grid-row-start: 2;
  grid-row-end: 6;
  grid-column-start: 1;
  grid-column-end: 2;
  background-color: #ececec;
  border-style: groove;
  margin: 15px 15px;
  border-radius: 5px;
}
.container-right-2{
  grid-column-start: 2;
  grid-column-end: 3;
  grid-row-start: 1;
  grid-row-end: 6;
  margin: 15px 15px;
}